<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <h4>PG PRO</h4>
      <b-card>
        <b-row match-height>
          <b-form-checkbox
            name="check-button"
            switch
            :value="1"
            :unchecked-value="0"
            @change="toggleAllGames()"
            v-model="toggleAll"
            >เปิดทุกเกม</b-form-checkbox
          >
        </b-row>
      </b-card>
      <transition name="fade">
        <b-card v-show="!toggleAll">
          <b-row match-height>
            <b-col
              sm="4"
              md="3"
              lg="2"
              v-for="item in pgproList"
              :key="item.id"
            >
              <b-card
                no-body
                class="border overflow-hidden"
                style="border-radius: 16px; position: relative"
              >
                <div
                  style="
                    position: absolute;
                    top: 10px;
                    right: 0;
                    background-color: red;
                    color: white;
                    padding: 4px 8px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    font-size: 12px;
                    font-weight: bold;
                    z-index: 10;
                  "
                >
                  PG PRO
                </div>

                <img
                  class="w-100 h-100"
                  :src="item.img"
                  style="object-fit: cover"
                />
                <div class="mt-1 text-truncate px-1 pb-1">
                  <h6>{{ item.name }}</h6>
                  <b-form-checkbox
                    @change="PGProchangeStatus(item)"
                    v-model="item.pgpro_status"
                    name="check-button"
                    switch
                    :value="1"
                    :unchecked-value="0"
                  ></b-form-checkbox>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </transition>
    </b-overlay>
  </div>
</template>


<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BPagination,
  BTable,
  BCard,
  BCardTitle,
  BCardBody,
  BOverlay,
  BIconController,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment-timezone";

export default {
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BCard,
    BCardTitle,
    BCardBody,
    BOverlay,
    BIconController,
    BButton,
    flatPickr,
  },
  data: () => ({
    pgproList: [],
    isLoading: false,
    toggleAll: 0,
  }),
  mounted() {
    this.getDataPgpro();
    this.getDataPgproAll();
  },
  methods: {
    async toggleAllGames() {
      this.isLoading = true;
      try {
        const payload = {
          pgpro_status: this.toggleAll,
        };
        await this.$http.post(`/pgpro/changeStatusAll`, payload);
        this.getDataPgproAll();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async PGProchangeStatus(item) {
      this.isLoading = true;
      try {
        // console.log(item);

        await this.$http.post(`/pgpro/changeStatus`, item);

        this.getDataPgpro();
        // console.log(data)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getDataPgproAll() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(`/pgpro/statusallgames`);
        this.toggleAll = data.pgpro_status;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getDataPgpro() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(`/pgpro/gamelist`);
        this.pgproList = data.Data;
        // console.log(data.Data)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>